import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { TranslatedBundleGuard } from '@dialog-eservices-enablement/angular-components';
import { HomePageComponent } from './features/sample-feature/components/home-page/home-page.component';
import { LabDetailComponent } from './features/sample-feature/components/lab-detail/lab-detail.component';
import { LaboratoryComponent } from './features/sample-feature/components/laboratory/laboratory.component';
import { OverviewComponent } from './features/sample-feature/components/overview/overview.component';
import { ViewAdvisoriesComponent } from './features/sample-feature/components/view-advisories/view-advisories.component';
import { ViewAdvisoriesDetailComponent } from './features/sample-feature/components/view-advisories-detail/view-advisories-detail.component';
import { FschartComponent } from './features/sample-feature/components/fschart/fschart.component';
import { IframeGuardService } from './features/sample-feature/services/iframe-guard.service';

// Route matcher function for handling specific patterns
const fschartMatcher = (url: UrlSegment[]) => {
  console.log("Inside fschartMatcher: ", url);
  return url.length && url[0].path.includes('fschart') ? ({ consumed: url }) : null;
};

export const appRouterModule: Routes = [
  {
    path: 'app-enabler',
    component: HomePageComponent,
    canActivate: [IframeGuardService],
    children: [
     
      {
        path: 'overview',
        component: OverviewComponent
      },
      {
        path: 'laboratory',
        component: LaboratoryComponent
      },
      {
        path: 'view-advisories',
        component: ViewAdvisoriesComponent
      },
      {
        path: 'lab-detail/:id',
        component: LabDetailComponent
      },
      {
        path: 'view-advisories-detail/:id',
        component: ViewAdvisoriesDetailComponent
      }
    ]
  },
  {
    path: 'fschart',
    component: FschartComponent
  },
  {
    path: '',
    redirectTo: '/app-enabler',
    pathMatch: 'full'
  }, // Default route
  {
    matcher: fschartMatcher,
    // redirectTo: '/fschart'
    component: FschartComponent
  },
  {
    path: '**',
    redirectTo: '/app-enabler'
  } 
];
@NgModule({
  imports: [RouterModule.forRoot(appRouterModule, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRouterModule {}
