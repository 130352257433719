
 <div class="chart-container" *ngIf="firewallPieData && firewallPieData.length > 0">
    <canvas id="firewallPieChart" >{{ chart }}</canvas>
  </div>

  <div class="chart-container"   *ngIf="devicesChart && devicesPieData.length > 0" >
    <canvas id="devicePieChart" >{{ chart }}</canvas>
  </div>

 
    <div class="chart-container" *ngIf="iframefirewallPieData && iframefirewallPieData.length > 0">
        <canvas id="sdsPieChart" class="sds-chart-container"  >{{ chart }}</canvas>
    </div>
