import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { snackbarService } from '@one/web-components';
import * as msgJson from '../msg.js';

interface Account {
  account_name: string;
  account_number: string;
  account_primary: string;
  crmNumber: string;
  erpNumber: string;
  sales_org: string;
  license: {
    Firewall: { id: number; expired: boolean; expiry_date: number };
    Vulnerabilities: { id: number; expired: boolean; expiry_date: number };
    expired: boolean;
    SoC: { id: number; expired: boolean; expiry_date: number };
  };
}

interface StatusCode {
  code: string;
  status: string;
  message: string;
  response: any;
}
@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  public constructor(private http: HttpClient) {}
  public laboratories = new BehaviorSubject<Account[]>([]);
  public selectedLab = new BehaviorSubject<Account[]>([]);
  public deviceData = new BehaviorSubject({});
  public deviceStatus = new BehaviorSubject({});
  public devicePageParams = new BehaviorSubject({});
  public mulesoftDevices = new BehaviorSubject<any>([]);
  public firewallData = new BehaviorSubject({});
  public countryCode = new BehaviorSubject<string>('');
  public invisibleCount = new BehaviorSubject<number>(0);
  public paginatedDevices = new BehaviorSubject<any>([]);
  public env = new BehaviorSubject<string>('/ss');
  public firewallExpanded = new BehaviorSubject<boolean>(true);
  public now = new Date();
  public vulnAdvisoryDetails = new BehaviorSubject({ searchTxt: '', searched: false, selectedDays: 0, startDate: new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() - 7), endDate: this.now, sortBy: ['publish_date'], advanceFilterOption: {}, redioFilterSelected: 'basic', selectedCategories: [], selectedProduct: [], selectedVendor: [], modifiedStartDate: '', publishedStartDate: '', publishedEndDate: '', modifiedEndDate: '', severityStart: 0, severityEnd: 10, selectedSeverity: 5 });
  public advisoryData = new BehaviorSubject<object[]>([]);
  public vulnAdvisoryPagination = new BehaviorSubject({ page: 1, rowsPerPage: 10, total: 1 });

  public fnUrl = 'fn/api/v1';
  public vmsUrl = 'vms/api/v1';
  public msUrl = 'ms/api/v1';
  public licenseUrl = 'license/api/v1/config';
  public supportTicketUrl = 'support/api/v1';
  public sdsUrl = 'sds/api/v1';

  public getFormattedDate(inputDateTimeString: string): string {
    const inputDate = new Date(inputDateTimeString);

    // Check if the input includes time or not
    if (isNaN(inputDate.getTime())) {
      // If the date is not valid, return the input as is
      return inputDateTimeString;
    }

    // Format the date manually
    const day = inputDate.getDate();
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = monthNames[inputDate.getMonth()];
    const year = inputDate.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  }

  public getAccounts(url: string): any {
    return this.http.get(`${url}`);
  }

  public getDevicesFromMulesoft(url: string): any {
    return this.http.get(`${url}`);
  }

  public getDevicesWithPagination(url: string, params: { page: 1; rowsPerPage: 10; total: 1; isTrusted: true; rexisDevicesCount: 0; s3DevicesCount: 0 }): any {
    if (params?.isTrusted) {
      params = { page: 1, rowsPerPage: 10, total: 1, isTrusted: true, rexisDevicesCount: 0, s3DevicesCount: 0 };
    }
    return this.http.get(`${url}`, {
      params
    });
  }

  public getProducts(url: string, params: any = {}): any {
    return this.http.get(`${url}`, { params });
  }

  public getProductVulnerabilities(url: string, params: { page: 1; rowsPerPage: 10; total: 1; isTrusted: true }): any {
    if (params?.isTrusted) {
      params = { page: 1, rowsPerPage: 10, total: 1, isTrusted: true };
    }
    return this.http.get(`${url}`, {
      params
    });
  }

  public getVulnerabilityAdvisories(url: string, params: { page: 1; rowsPerPage: 10; total: 1; type: ''; isTrusted: true; startDate: ''; endDate: ''; publishedStartDate: ''; publishedEndDate: '' }): any {
    if (params?.isTrusted) {
      params = { page: 1, rowsPerPage: 10, total: 1, isTrusted: true, type: params?.type, startDate: params?.startDate, endDate: params?.endDate, publishedStartDate: '', publishedEndDate: '' };
    }
    if (params?.startDate || params?.endDate) {
      params.publishedStartDate = params.startDate;
      params.publishedEndDate = params.endDate;
      delete params.startDate;
      delete params.endDate;
    }
    return this.http.post(`${url}`, params);
  }

  public getVulnerabilityFilterData(url: string): any {
    return this.http.get(`${url}`);
  }

  public getVulnerabilityAdvisorieDetail(url: string): any {
    return this.http.get(`${url}`);
  }

  public getFirewallStatus(url: string, labIds:any, reportStatus: boolean): any {
    const params = { report_status: reportStatus, visible: true };
    url = `${url}?lab_ids=${labIds}`;
    return this.http.get(`${url}`, { params });
  }

  public downloadOrGenerateReport(url: string): any {
    return this.http.get(`${url}`);
  }

  public updateDevices(url: string, deviceDetails: any): any {
    return this.http.post(`${url}`, deviceDetails);
  }

  public createTrialLicense(url: string, params: any): any {
    return this.http.post(`${url}`, params);
  }
  public applyCentricView(url: string, labs: any): any {
    return this.http.post(`${url}`, labs);
  }

  public submitTicket(url: string, params: any): any {
    return this.http.post(`${url}`, params);
  }

  public showNotification(msg: string, msgType: any, url: string): void {
    setTimeout(() => {
      const messages = Array.from(document.querySelector('body > owc-notification-drawer').shadowRoot.querySelectorAll('owc-snackbar')).map(element => {
        return element.shadowRoot.querySelector('owc-typography').innerText;
      });

      if (!messages.includes(msg)) {
        const msgToShow = url === '' ? msg :'<div style="width:108%">'+ msg + '</br >'+
        '<a  href="' + url + '" target="_blank" style="float: right; display: block;width: 150px;height: auto; background: rgb(239, 239, 239); padding: 10px; text-align: center; border-radius: 5px;color: rgb(0, 102, 204);text-decoration: none">Purchase license </a></div>';
        const duration = url === '' ? 5000 : 5000;
        snackbarService.show({
          message: '',
          htmlMessage: msgToShow,
          action: '',
          actionBtn: () => console.log('action button clicked'),
          duration: duration,
          open: (): void => console.log('open snackbar'),
          close: (): void => console.log('close snackbar'),
          type: msgType,
          verticalPlacement: 'top',
          horizontalPlacement: 'right'
        });
      }
    }, 300);
  }

  public notify(response: StatusCode, msgType: string, showAlert: boolean = true,  url: string = ''): any {
    this.adjustNotificationDrawerStyle();

    const message = this.getMessageFromResponse(response);

    if (showAlert) {
      this.showNotification(message, msgType, url);
    }
    return message;
  }

  private adjustNotificationDrawerStyle(): void {
    document.querySelectorAll('owc-notification-drawer').forEach(el => {
      el.style.zIndex = '1002';
      el.style.position = 'relative';
    });
  }

  private getMessageFromResponse(response: StatusCode): string {
    let message = '';
    if (response?.code) {
      message = this.getMessageFromCodeResponse(response);
    } else {
      message = this.getDefaultMessage(response);
    }
    return message;
  }

  private getMessageFromCodeResponse(response: StatusCode): string {
    let message = '';
    if (response?.response?.data?.statusCode === 201) {
      message = response?.response?.data?.id + ' : ' + response?.message;
    } else {
      const msgObj = this.getMessageObject(response);
      message = msgObj?.msg;
    }

    if (message === '' || message === undefined) {
      message = this.getDefaultMessage(response);
    }
    return message;
  }

  private getMessageObject(response: StatusCode): any {
    let msgObj: any;
    msgJson?.messages.forEach(obj => {
      if (obj.id === response?.code?.toString()) {
        msgObj = obj;
      }
    });
    return msgObj;
  }

  private getDefaultMessage(response: StatusCode): string {
    return response?.message ? response?.message : $localize`:@@something-went-wrong:Something went wrong`;
  }
}
