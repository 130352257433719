import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthApiService } from '@dialog-eservices-enablement/angular-components';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthApiService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const contentType = request.headers.get('Content-Type');
    // Check if the URL contains '/apiproxy', then pass the request without modifying headers
    if (request.url.includes('/apiproxy')) {
      return next.handle(request);
    }
    return from(this.authService.getCurrentUser()).pipe(
      switchMap(currentUser => {
        if (currentUser && contentType !== 'application/octet-stream') {
          const authReq = request.clone({
            headers: request.headers
              .set('Authorization', 'Bearer ' + currentUser['jwt'])
              .set('Content-Type', 'application/json')
              .set('Access-Control-Allow-Origin', '*')
          });
          return next.handle(authReq);
        }
        return next.handle(request);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return from(this.authService.getCurrentUser()).pipe(
            switchMap(user => {
              if (user && contentType !== 'application/octet-stream') {
                const authReq = request.clone({
                  headers: request.headers
                    .set('Authorization', 'Bearer ' + user['jwt'])
                    .set('Content-Type', 'application/json')
                    .set('Access-Control-Allow-Origin', '*')
                });
                return next.handle(authReq);
              } else if (user && contentType === 'application/octet-stream') {
                return next.handle(request);
              } else {
                this.authService.signOut();
                return throwError(error);
              }
            })
          );
        }
        return throwError(error);
      })
    );
  }
}
