 <!--  Firewall Pie Chart-->
 <div id="top-container" class="top-container">
    <div id="main-page" class="container" style="background: #fff;">
      <owc-card id="fschart-feature-card" flat scroll-shadow class="owc-card" [ngClass]="{  activate:  true }">
        <div slot="header" class="header-section" [ngClass]="isFrameSmall ? 'feature-desc' : ''">
          <div class="row">
            <div id="fschart-feature-icon" class="zero">
              <owc-icon #iconElement name="security" type="legacy" class="mat-header-icon" [style]="{ color: 'blue' }"> </owc-icon>
            </div>
            <div class="two" id="fschart-feature-name" >
              <h3  title="In this area, you will receive an overview of the status of your firewall and have access to your firewall report." style="margin: 0px 3px; word-break: break-all;position: absolute;" i18n="@@firewall-protection">Firewall protection</h3>
            </div>
            <div *ngIf="!isFrameSmall" id="fschart-feature-desc" class="three " [style]="{ display: 'grid' }" i18n="@@firewall-protection-desc">
              In this area, you will receive an overview of the status of your firewall and have access to your firewall report.</div>
          </div>
        </div>
        <div style="margin:auto 0; overflow-x: hidden; width: 100%; height: 100%;">
          <dl-doughnut-chart  [iframefirewallPieData]="firewallPieData" *ngIf=" this.totalFirewallDevices > 0; else loader" [style.margin]="isFrameSmall? '1px 0px': 'auto 0px' "></dl-doughnut-chart>
        </div>
        <ng-template #loader>
          <div *ngIf="!(this.firewallPieData.length > 0)" class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </ng-template>
        <div slot="footer" *ngIf=" this.totalFirewallDevices > 0 &&  !this.loading " style="margin-top: auto;margin-bottom: 3px;">
          <a target="_blank" [href]="viewStatusUrl">
            <owc-button  variant="secondary" style="float: right;margin-right: 4px;" [ngClass]="isFrameSmall ? 'feature-desc' : ''" i18n="@@view-status-btn"> View status</owc-button>
          </a>
        </div>
      </owc-card>
  </div>
 </div>