import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Components } from '@one/web-components';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureService } from '../../services/feature.service';
import { AuthService } from '@dialog-eservices-enablement/angular-components';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from 'projects/dialog/src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../common/popup/popup.component';
import { LoadingBarService } from '@dialog-eservices-enablement/angular-components';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface Account {
  account_name: string;
  account_number: string;
  account_primary: string;
  crmNumber: string;
  erpNumber: string;
  sales_org: string;
  is_lab_selected: boolean;
  license: {
    Firewall: { id: number; expired: boolean; expiry_date: number; license_status: boolean; license_type: string };
    Vulnerabilities: { id: number; expired: boolean; expiry_date: number; license_status: boolean; license_type: string };
    Advisories: { id: number; expired: boolean; expiry_date: number; license_status: boolean; license_type: string };
    expired: boolean;
    SoC: { id: number; expired: boolean; expiry_date: number; license_status: boolean; license_type: string };
  };
}

@Component({
  selector: 'dl-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy {
  @ViewChild('iconElement', { static: true }) private iconElement: Components.OwcIcon | undefined;
  @ViewChild('buttonElement', { static: true }) private buttonElement!: Components.OwcButton;
  @ViewChild('editableDropdownElement', { static: true }) editableDropdownElement!: Components.OwcEditableDropdown;

  private selectedCentricLabs: string = '';
  public selectedLab: any;
  public status = {};
  public data: any;
  public subs: Subscription[] = [];
  public countryCode: string;
  public mainDesc: string;
  public firewallLicense: number;
  public advisoriesLicense: number;
  public vulnerabilityLicence: number;
  public socAndCombinedLicense: number;
  public sdsData: any;
  public isLoading: boolean;
  public selectedProduct: any;
  public constructor(private router: Router, private activatedRoute: ActivatedRoute, private featureService: FeatureService, private authService: AuthService, public dialog: MatDialog, private loadingBarService: LoadingBarService, private sanitizer: DomSanitizer) {}
  public env: string;
  public latestVulnCount;
  public endDate: Date;
  public startDate: Date;
  public expiryDays: number;
  public totalFirewallDevices = 0;
  public chart: any;
  public barChart: any;
  public firewallPieData = [];
  public vulnerabilityChartData: any;
  public vulnerabilityChartFlag: boolean;
  public devicesChartFlag: boolean;
  public devicesPieData = [];
  public languageCode = 'en_us';
  public selectedLabIds = [];
  public fschartUrl: SafeResourceUrl;
  public getLastWeeksDate(days: number): void {
    const now = new Date();
    this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - days);
  }

  public ngOnInit(): void {
    this.languageCode = this.authService?.['countryLanguageService']?.['languageCode'] ? this.authService['countryLanguageService']['languageCode'] : 'en_us';
    console.log('languageCode : ', this.languageCode);
    this.initializeVariables();
    this.sdsData = [];
    this.subscribeToEnv();
    this.subscribeToCountryCode();
    this.subscribeToSelectedLab();
    this.subscribeToFirewallData();
    this.getSDSDeviceDetails();
    this.fschartUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/dialog/' + this.countryCode.toLowerCase() + '/' + this.languageCode.toLocaleLowerCase() + '/fschart');
    // this.fschartUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/dialog/' + 'gb' + '/' + this.languageCode.toLocaleLowerCase() + '/fschart');
    console.log('fschart : ', this.fschartUrl);
    this.loadingBarService.isLoading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  public initializeVariables(): void {
    this.vulnerabilityChartData = {};
    this.vulnerabilityChartFlag = false;
    this.devicesChartFlag = false;
    this.expiryDays = 7;
    this.selectedLab = {};
    this.latestVulnCount = 0;
    this.endDate = new Date();
    this.getLastWeeksDate(7);
  }

  public subscribeToEnv(): void {
    this.subs.push(
      this.featureService.env.subscribe(env => {
        this.env = env;
      })
    );
  }

  public subscribeToCountryCode(): void {
    this.subs.push(
      this.featureService.countryCode.subscribe(countryCode => {
        this.countryCode = countryCode;
      })
    );
  }

  public subscribeToSelectedLab(): void {
    this.subs.push(
      this.featureService.selectedLab.pipe(take(1)).subscribe((selAccountData: Account[]) => {
        this.selectedLabIds = selAccountData.map(lab => lab.crmNumber);
        if (selAccountData && selAccountData.length == 1) {
          this.handleSelectedLab(selAccountData[0]);
        } else if (selAccountData && selAccountData.length > 1) {
          let selectedSoCLabs = [];
          selAccountData.forEach((item, index) => {
            if (item.is_lab_selected) {
              selectedSoCLabs.push(item);
            }
          });
          this.handleSelectedLab(selectedSoCLabs);
        }
      })
    );
  }

  public handleSelectedLab(selectedLab: any): void {
    this.selectedLab = selectedLab;
    this.getLicenses();
    if (this.selectedLabIds.length > 1) {
      // selectedCentricLabs to show name of all selected labs in the header
      selectedLab.forEach((item, index) => {
        if (selectedLab.length - 1 !== index) {
          this.selectedCentricLabs = this.selectedCentricLabs + item.account_name + ',  ';
        } else {
          this.selectedCentricLabs = this.selectedCentricLabs + item.account_name;
        }
      });
    }
    let marketPlaceUrl = '';
    const expiredLicenses = this.getExpiredLicenses();
    if (expiredLicenses !== '') {
      if (environment.marketplaceUrl) {
        marketPlaceUrl = environment.marketplaceUrl;
        // marketPlaceUrl = environment.marketplaceUrl + '/' + this.countryCode.toLocaleLowerCase() + '/' + this.languageCode + '/products/security-suite';
        console.log('marketPlace Url : ', marketPlaceUrl);
      }
      this.showWarningMessage(expiredLicenses, marketPlaceUrl);
    }
  }

  public getExpiredLicenses(): string {
    let expiredLicenses = '';
    if (this.selectedLabIds.length > 1) {
      this.selectedLab.map(lab => {
        let labNameAdded = false; // Flag to track if labName has been added
        Object.entries(lab.license).forEach(([key, value]) => {
          const licenseExpired = this.checkLicenseExpiryDate(value);
          if (licenseExpired.expired === true) {
            let licenseLabel;
            if (!labNameAdded) {
              // Prepend lab.crmNumber before the first expired license of the lab
              licenseLabel = lab.crmNumber + ':' + key;
              labNameAdded = true; // Update flag after adding
            } else {
              // For subsequent licenses, just use the key as the label
              licenseLabel = key;
            }
            expiredLicenses = expiredLicenses !== '' ? expiredLicenses + ', ' + licenseLabel : licenseLabel;
            if (licenseExpired.daysPending > 0) {
              expiredLicenses = expiredLicenses + ' (' + licenseExpired.daysPending + $localize`:@@days-text: days` + ')';
            }
          }
        });
      });
    } else {
      Object.entries(this.selectedLab?.license).forEach(([key, value]) => {
        const licenseExpired = this.checkLicenseExpiryDate(value);
        if (licenseExpired.expired === true) {
          expiredLicenses = expiredLicenses !== '' ? expiredLicenses + ', ' + key : key;
          if (licenseExpired.daysPending > 0) {
            expiredLicenses = expiredLicenses + ' (' + licenseExpired.daysPending + $localize`:@@days-text: days` + ')';
          }
        }
      });
    }
    return expiredLicenses;
  }

  public showWarningMessage(expiredLicenses: string, marketPlaceUrl: string): void {
    const yourText = $localize`:@@your-text:Your`;
    const warningMessage = $localize`:@@test-license-expire-msg:license(s) expiring soon. Please contact us to continue`;
    const expiredLicensesMsg = $localize`:@@purchase-license-url:or click purchase license button.`;
    const dateExpiryWarningMsg = `${yourText} ${expiredLicenses} ${warningMessage} ${expiredLicensesMsg}`;
    this.warningMsg(dateExpiryWarningMsg, marketPlaceUrl);
  }

  public subscribeToFirewallData(): void {
    let firewallData: {} = {};
    let stsColor = 'grey';
    this.subs.push(
      this.featureService.firewallData.pipe(take(2)).subscribe((fd: any) => {
        firewallData = fd;
        stsColor = this.verifyLicense(firewallData);
        this.setData(stsColor);
      })
    );
  }

  public setData(stsColor: string): void {
    this.data = {
      desc: $localize`:@@overview-tab-desc:Businesses are experiencing an increase in both the volume and sophistication of cyber attacks. Our Security Suite offers a comprehensive solution to give you peace of mind.`,
      subdesc: $localize`:@@overview-tab-subdesc:There are no suggested actions in your laboratory.`,
      features: [
        {
          id: 'firewall-protection',
          expired: this.selectedLabIds.length === 1 ? this.firewallLicense === 0 || this.socAndCombinedLicense === 0 : false,
          licenseType: this.selectedLabIds.length === 1 ? this.selectedLab?.license?.Firewall?.license_type : 'SoC',
          name: $localize`:@@firewall-protection:Firewall protection`,
          desc: $localize`:@@firewall-protection-desc:In this area, you will receive an overview of the status of your firewall and have access to your firewall report.`,
          status: this.selectedLabIds.length === 1 ? (this.firewallLicense === 1 || this.socAndCombinedLicense === 1 ? stsColor : 'grey') : stsColor,
          extra: this.selectedLabIds.length === 1 ? (this.firewallLicense === 1 || this.socAndCombinedLicense === 1 ? 'View status' : 'Activate license') : 'View status',
          imagePath: 'assets/Firewall-tile-no-license.png'
        },
        {
          id: 'devices',
          expired: this.selectedLabIds.length === 1 ? this.vulnerabilityLicence === 0 || this.socAndCombinedLicense === 0 : false,
          licenseType: this.selectedLabIds.length === 1 ? this.selectedLab?.license?.Vulnerabilities?.license_type : 'SoC',
          name: $localize`:@@devices:Devices`,
          desc: $localize`:@@vulnerabilities-desc:Here you will find an overview of the devices in your environment.`,
          status: 'grey',
          extra: this.selectedLabIds.length === 1 ? (this.vulnerabilityLicence === 1 || this.socAndCombinedLicense === 1 ? 'View devices' : 'Activate license') : 'View devices',
          imagePath: 'assets/device-tile-no-license.png'
        },
        {
          id: 'vulnerability-advisories',
          expired: this.selectedLabIds.length === 1 ? this.advisoriesLicense === 0 || this.socAndCombinedLicense === 0 : false,
          licenseType: this.selectedLabIds.length === 1 ? this.selectedLab?.license?.Advisories?.license_type : 'SoC',
          name: $localize`:@@vulnerabiliries-advisories:Vulnerability advisories`,
          desc: $localize`:@@vulnerability-advisories-desc:In this area, you will see an overview of the weak points in your environment as well as recommendations for measures.`,
          status: 'grey',
          extra: this.selectedLabIds.length === 1 ? (this.socAndCombinedLicense === 1 || this.advisoriesLicense === 1 ? 'View advisories' : 'Activate license') : 'View advisories',
          imagePath: 'assets/advisory-tile-no-license.png'
        },
        {
          id: 'software-distribution',
          name: $localize`:@@software-distribution: Software distribution`,
          desc: $localize`:@@software-distribution-desc:Information on the provision of software updates for Roche devices can be found in this area.`,
          status: 'grey',
          extra: 'View packages',
          imagePath: ''
        }
      ]
    };
  }
  public epochToJsDate(ts: any): Date {
    return new Date(ts * 1000);
  }

  public checkLicenseExpiryDate(license: any): { expired: boolean; daysPending: number } {
    if (license?.expired === true) {
      return { expired: true, daysPending: 0 };
    }
    const dateOffset = 24 * 60 * 60 * 1000 * this.expiryDays;
    const expiryDate = this.epochToJsDate(license?.expiry_date);
    const todaysDate = new Date();
    const expiringDate = expiryDate.getTime() - dateOffset;
    const daysPending = Math.ceil((expiryDate.getTime() - todaysDate.getTime()) / (24 * 60 * 60 * 1000));
    if (todaysDate.getTime() >= expiringDate) {
      return { expired: true, daysPending };
    } else {
      return { expired: false, daysPending };
    }
  }

  public warningMsg(msg: string, marketPlaceUrl: string): void {
    const obj = { code: '', status: '', message: msg, response: {} };
    this.featureService.notify(obj, 'warning', true, marketPlaceUrl);
  }

  public viewStatus(): void {
    this.featureService.firewallExpanded.next(true);
    this.router.navigate(['app-enabler/laboratory']);
  }

  public viewDevices(): void {
    this.featureService.firewallExpanded.next(false);
    this.router.navigate(['app-enabler/laboratory']);
  }

  public viewAdvisories(): void {
    this.router.navigate(['app-enabler/view-advisories']);
  }

  public getLicenses(): void {
    if (this.selectedLabIds.length === 1) {
      this.firewallLicense = this.selectedLab?.license?.expired === false && this.selectedLab?.license?.Firewall?.expired === false ? 1 : 0;
      this.vulnerabilityLicence = this.selectedLab?.license?.expired === false && this.selectedLab?.license?.Vulnerabilities?.expired === false ? 1 : 0;
      this.socAndCombinedLicense = this.selectedLab?.license?.expired === false && this.selectedLab?.license?.SoC?.expired === false ? 1 : 0;
      this.advisoriesLicense = this.selectedLab?.license?.expired === false && this.selectedLab?.license?.Advisories?.expired === false ? 1 : 0;
    }
    if (this.socAndCombinedLicense === 1 || this.advisoriesLicense === 1 || this.selectedLabIds.length > 1) {
      const startDate = Math.floor(new Date(this.startDate).getTime());
      const endDate = Math.floor(new Date(this.endDate).getTime());
      this.getLast7DaysVulnerabilityCount({ page: 0, rowsPerPage: 10, total: 1, type: 'count', startDate, endDate });
    }

    if (this.vulnerabilityLicence === 1 || this.socAndCombinedLicense === 1 || this.selectedLabIds.length > 1) {
      this.getDevicesGraphData();
    }
  }

  public verifyLicense(firewallData: any): any {
    const sts = this.getFirewallStatus(firewallData);
    return sts;
  }

  public activateLicense(license: any): void {
    const data = { title: $localize`:@@access-denied:Access denied`, body: $localize`:@@access-denied-msg:You don\'t have access to view details of this lab as this lab is not licensed under the agreement.`, primaryBtn: $localize`:@@close-btn:Close`, activateTrialLicenseBtn: $localize`:@@activate-trial-license-btn:Activate trial license`, purchaseLicenseBtn: $localize`:@@purchase-license-btn:Purchase license`, license, feature: 'license' };
    this.dialog.open(PopupComponent, { data });
  }

  public getFirewallStatus(firewallData: any): any {
    if (!firewallData || firewallData === '' || Object.keys(firewallData).length === 0) {
      return 'blue';
    }

    this.totalFirewallDevices = Object.keys(firewallData).length;
    const sts = this.getStatus(firewallData);
    this.setFirewallPieData(firewallData);

    return sts;
  }

  private getStatus(firewallData: { [key: string]: { conf_status?: string; conn_status?: string } }): string {
    for (const value of Object.values(firewallData)) {
      if (value?.conf_status !== 'insync' || value?.conn_status !== 'up') {
        return 'blue';
      }
    }
    return 'grey';
  }

  private setFirewallPieData(firewallData: any): void {
    this.firewallPieData = [
      { name: $localize`:@@firewall-tile-configured-connected:Configured & Connected`, value: 0, color: 'rgb(0, 124, 100)' },
      { name: $localize`:@@firewall-tile-configured-notconnected:Configured & Not connected`, value: 0, color: 'rgb(4, 188, 162)' },
      { name: $localize`:@@firewall-tile-notconfigured:Not configured`, value: 0, color: 'rgb(176, 202, 162)' },
      { name: $localize`:@@firewall-tile-not-found-at-backend:Not found at backend`, value: 0, color: 'rgba(0, 145, 140, 0.26)' }
    ];

    for (const value of Object.values(firewallData)) {
      this.incrementPieData(value);
    }
  }

  private incrementPieData(value: any): void {
    if (value?.conf_status === 'insync' && value?.conn_status === 'up') {
      this.firewallPieData[0].value += 1;
    } else if (value?.conf_status === 'insync' && value?.conn_status !== 'up') {
      this.firewallPieData[1].value += 1;
    } else if (value?.conf_status && value?.conf_status !== 'insync') {
      this.firewallPieData[2].value += 1;
    } else {
      this.firewallPieData[3].value += 1;
    }
  }

  public getLast7DaysVulnerabilityCount(params: any): void {
    let crmNumber = '';
    if (this.selectedLabIds.length === 1) {
      crmNumber = this.selectedLab.crmNumber;
    } else {
      crmNumber = this.selectedLab[0].crmNumber;
    }
    const url = `${this.env}/${this.featureService.vmsUrl}/${this.countryCode}/${crmNumber}/vulnerabilities/`;

    this.subs.push(
      this.featureService.getVulnerabilityAdvisories(url, params).subscribe(
        response => {
          if (response?.status === 'SUCCESS' || response?.status === 'PARTIAL') {
            this.latestVulnCount = response?.response?.filtered ? response?.response?.filtered : 0;
            this.vulnerabilityChartData = response?.response?.data ? response?.response?.data : {};
            if (this.latestVulnCount > 0 && Object.keys(this.vulnerabilityChartData).length !== 0) {
              this.vulnerabilityChartFlag = true;
            }
          } else {
            // code to show message box
            this.featureService.notify(response, 'error', false);
          }
        },
        error => {
          console.log('getVulnerabilityAdvisories error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error', false);
        }
      )
    );
  }

  public getDevicesGraphData(): void {
    this.devicesChartFlag = true;
    let s = 0;
    const colors = ['#5196DC', '#BC9623', '#DF6080', '#AD622F', '#3A899B', '#3A6D6A', '#B2046B', '#8B0E8E', '#0E5AA5', '#333333', '#DBEFC5', '#EFEFEF', '#D60078', '#4D4D4D', '#033A38', '#737373', '#1D4D7E', '#BA835D', '#D395D8', '#09647A'];
    const url = `${this.env}/${this.featureService.msUrl}/${this.countryCode}/devices/?instrumentFamilyCount=true&lab_ids=${this.selectedLabIds}`;
    this.subs.push(
      this.featureService.getDevicesFromMulesoft(url).subscribe(
        (response: any) => {
          if (response?.status === 'SUCCESS') {
            const devicesPieChartData = response?.response?.data;
            for (const [k, v] of Object.entries(devicesPieChartData)) {
              if (k === 'Others' && devicesPieChartData[k] === 0) {
                continue;
              } else {
                this.devicesPieData.push({ name: k, value: v, color: colors[s] });
              }
              s++;
            }
          } else {
            //  code to show message box
            this.featureService.notify(response, 'error');
          }
        },
        error => {
          console.log('getDevices count in overview error: ' + error);
          this.featureService.notify(error?.error ? error.error : error, 'error');
        },
        () => {}
      )
    );
  }

  public ngOnDestroy(): void {
    const pageParams = { page: 1, rowsPerPage: 10, total: 1, rexisDevicesCount: 0, s3DevicesCount: 0 };
    this.featureService.devicePageParams.next(pageParams);
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public getSDSDeviceDetails(): void {
    const url = `${this.env}/${this.featureService.sdsUrl}/products/${this.countryCode.toLowerCase()}/${this.languageCode}/`;
    // console.log('SDS url : ', url);
    this.subs.push(
      this.featureService.getProducts(url).subscribe(
        (response: any) => {
          if (response?.status === 'SUCCESS') {
            const sdsRawData = response?.response;
            let sdsData = [];
            sdsRawData.forEach(item => {
              if (item.packages && item.packages.length > 0) {
                // Find the latest package by updatedAt date
                const latestPkg = item.packages.reduce((latest, pkg) => {
                  return new Date(pkg.updatedAt) > new Date(latest.updatedAt) ? pkg : latest;
                });

                // Add the latestPkgDate attribute with formatted date
                sdsData.push({ ...item, latestPkgDate: latestPkg.updatedAt.split('T')[0] }); // Extract only the date part
              }
            });
            this.addIsLatestAttribute(sdsData);
          } else {
            //  code to show message box
            //  this.featureService.notify(response, 'error');
            console.log('Sometging error in getSDSDeviceDetails in overview');
          }
        },
        error => {
          console.log('getSDSDevicesDetails in overview error: ' + JSON.stringify(error));
        },
        () => {}
      )
    );
  }

  stripTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public addIsLatestAttribute(data: any[]) {
    const today = new Date();
    const twoWeeksAgoDate = new Date(today.setDate(today.getDate() - 16));

    data.forEach(item => {
      if (item.latestPkgDate) {
        const pkgDate = new Date(item.latestPkgDate);
        const strippedPkgDate = this.stripTime(pkgDate);
        const strippedTwoWeeksAgo = this.stripTime(twoWeeksAgoDate);

        item.isLatest = strippedPkgDate >= strippedTwoWeeksAgo;
      } else {
        item.isLatest = false;
      }
    });
    this.sdsData = data;
  }

  public showProduct(row: any): void {
    let selectedProductUrl = '';
    if (row && row.name) {
      selectedProductUrl = row.redirectUrl;
    } else {
      selectedProductUrl = this.sdsData[0].redirectUrl.split('?')[0];
    }
    const targetUrl = environment.softwareDistributionUrl + selectedProductUrl;
    console.log('targetUrl : ', targetUrl);
    window.open(targetUrl, '_blank');
  }
}
