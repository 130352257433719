import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild, ViewEncapsulation, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { View } from './shared/models/view';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { environment } from 'projects/dialog/src/environments/environment';
import { AvailabilitiesService, UserEServicesService, AuthService, RegionDialogService } from '@dialog-eservices-enablement/angular-components';
import { Router } from '@angular/router';
import { OneTrustService } from 'projects/dialog/src/app/core/services/one-trust.service';

@Component({
  selector: 'dl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('eserviceAvailabilityError') private eServicesAvailabilityMessage: ElementRef;
  @ViewChild('userEservicesError') private userEservicesMessage: ElementRef;

  public marketplaceUrl: string;
  public View = View;
  public view$: Observable<View> = this.breakpointObserver.observe('(min-width: 600px)').pipe(
    map((result: BreakpointState) => result.matches),
    map((isDesktop: boolean) => {
      if (!isDesktop) {
        return View.Mobile;
      } else if (this.authService.isLoggedIn) {
        return View.DesktopLoggedIn;
      } else {
        return View.DesktopLoggedOut;
      }
    })
  );
  public showLoadingBar = true;
  public isLoggedIn = this.authService.isLoggedIn;
  public eServiceBaseUrl = '';
  public constructor(private renderer: Renderer2, private breakpointObserver: BreakpointObserver, private authService: AuthService, private availabilitiesService: AvailabilitiesService, private userEservices: UserEServicesService, private regionDialogService: RegionDialogService, private router: Router, public oneTrustService: OneTrustService) {}

  public ngOnInit(): void {
    if (!(window.self === window.top)) {
      this.oneTrustService.closeCookiePreferences();
    }
    this.showLoadingBar = window.self === window.top;
    this.regionDialogService.openDialog(true);
    if (this.authService.canOpenLoginDialog()) {
      this.authService.openLoginDialog();
    }

    if (environment.marketplaceUrl) {
      this.marketplaceUrl = environment?.marketplaceUrl;
    }
  }

  public ngAfterViewInit(): void {
    this.availabilitiesService.eServiceAvailabilityError = this.eServicesAvailabilityMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.userEservicesError = this.userEservicesMessage.nativeElement.previousSibling.wholeText;
    this.userEservices.userEServices$.subscribe(data => console.log(data));
    const continueWithoutLoginLink = document.querySelector('.continue-without-logging-in');

    this.renderer.listen(continueWithoutLoginLink, 'click', event => {
      if (this.marketplaceUrl) {
        window.location.href = this.marketplaceUrl;
      }
    });
  }
}
